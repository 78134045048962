import * as React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { ExternalLink, MainHeading, Markdown, SubPageLayout, SubSection, SubSections } from '../components';
import memberResourcesData from '../data/memberResources.json';
import _ from 'lodash';
import FaqEntry from '../components/FaqEntry/FaqEntry';

export const MemberResourceCenterPageInternal = (memberResources: typeof memberResourcesData) => {
  return (
    <SubPageLayout
      title="Member Resources"
      imageSrc={memberResources.headerImageSrc}
    >
      <MainHeading
        label="Member Resources"
        text={memberResources.subText} />

      <SubSections>
        {[
          <SubSection key="online-banking" anchor="online-banking" headerLabel="Online Banking">
            <Row>
              <Col md={12}>
                <Markdown source={memberResources.onlineBanking} />
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="mobile-banking" anchor="mobile-banking" headerLabel="Mobile Banking">
            <Row>
              <Col md={12}>
                <Markdown source={memberResources.mobileBanking} />
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="shazam-brella" anchor="shazam-brella" headerLabel="SHAZAM brella® DEBIT CARD MANAGEMENT APP">
            <Row>
              <Col md={12}>
                <Markdown source={memberResources.shazambrealla} />
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="faqs" anchor="faqs" headerLabel="FAQs">
            <Row>
              <Col md={12}>
                {
                  _.map(memberResources.faqs, (faq, i) => {
                    const isLast = i === _.size(memberResources.faqs) - 1;

                    return (
                      <React.Fragment key={i}>
                        <FaqEntry {...faq} defaultExpanded={false} />

                        {!isLast && <hr />}
                      </React.Fragment>
                    );
                  })
                }
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="greenpath" anchor="greenpath" headerLabel="GREENPATH™ FINANCIAL WELLNESS">
            <Row>
              <Col md={12}>
                <Markdown source={memberResources.greenpath} />
              </Col>
            </Row>
            <Row className="text-center">
              <Col md={12}>
                <Button tag={ExternalLink} href="https://www.greenpath.com/" color="primary">GreenPath Financial Wellness Website</Button>
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="trustage" anchor="trustage" headerLabel="TRUSTAGE® - AUTO, HOME, & LIFE INSURANCE">
            <Row>
              <Col md={12}>
                <Markdown source={memberResources.trustage} />
              </Col>
            </Row>
            <Row className="text-center">
              <Col md={12}>
                <Button tag={ExternalLink} href="https://www.trustage.com/" color="primary">TruStage™ Website</Button>
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="franklinMadison" anchor="franklinMadison" headerLabel="Franklin Madison">
            <Row>
              <Col md={12}>
                <Markdown source={memberResources.franklinMadison} />
              </Col>
            </Row>
          </SubSection>,
        ]}
      </SubSections>
    </SubPageLayout>
  );
};

export default () => <MemberResourceCenterPageInternal {...memberResourcesData} />;
