import React, { useState } from 'react';
import { Button, Col, Collapse, Row } from 'reactstrap';
import Markdown from '../Markdown/Markdown';

interface Props {
  title: string;
  description: string;
  defaultExpanded?: boolean;
}

const FaqEntry = ({ title, description, defaultExpanded }: Props) => {
  const [ isExpanded, setIsExpanded ] = useState<boolean>(!!defaultExpanded);
  return (
    <Row>
      <Col>
        <h5>{title}</h5>
        <div className="text-center text-md-left">
          <Button
            color="primary"
            size="sm"
            className="mb-1"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? <span><i className="fa fa-angle-up" /> Hide Answer <i className="fa fa-angle-up" /></span> : <span><i className="fa fa-angle-down" /> Show Answer <i className="fa fa-angle-down" /></span>}
          </Button>
        </div>

        <Collapse isOpen={isExpanded}>
          <Markdown source={description} />
        </Collapse>
      </Col>
    </Row>
  );
};

export default FaqEntry;
